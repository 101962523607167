/* eslint-disable camelcase */
import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import { getGoBackUrl, removeGoBackUrl } from '@/utils/gobackurl'
import { inBenlaiApp, bridge, parseSearch } from '@/utils/env'
import cookies from 'js-cookie'
import getCurrentQuery from '@/utils/querystrings'

declare module 'vue/types/vue' {
  interface Vue {
    storeCode: string
    source: string
    targetCode: string
    showErrorToast(err: any): void
    back(): void
    isWeixin(): boolean
    goTo(res: any, smstype:number, grantType:number): void
    // grantType 类型如：  用于登录接口
    //    3.Password 4.Auth_PhoneSms 5.Auth_WeChat 6.Auth_AliPay 7.Auth_QQ 8.Auth_Weibo 9.异常IP 10.异常设备  10以上第三方登录
    // smstype 类型：  用于获取短信验证码接口
    //    0.无 1.绑定手机号 2.设置/重置支付密码 3.短信快捷登录 4.验证手机 5.修改手机 6.修改登录密码
    //    7.城市更换 8.更换设备号 9.第三方 10.红包验证码 11.绑定优惠券 12.加密手机号短信登录
    isABC(): boolean
    compareVersion(curV: string, oldV: string): boolean
    codeExchangeUrl(code: string): void
  }
}

@Component
export default class GlobalMixin extends Vue {
  @Provide() public storeCode: string = ''
  @Provide() public source: string = ''
  @Provide() public targetCode = getCurrentQuery('targetCode') || ''
  @Provide() public waitTime: number = 2
  @Provide()
  public isABC () {
    if (navigator.userAgent.indexOf('Bankabc') > -1) {
      return true
    }
    if (navigator.userAgent.indexOf('com.bankcomm.maidanba') > -1) {
      return true
    }
    if (navigator.userAgent.indexOf('BoComMOBS') > -1) {
      return true
    }
    if (navigator.userAgent.indexOf('BOB') > -1) {
      return true
    }
    return false
  }
  @Emit()
  public showErrorToast (err: any) {
    const msg = err.msg || err.message
    if (msg) {
      this.$toasted.show(msg)
    }
  }
  @Emit()
  public back () {
    // window.history.go(-1)
    if (location.href.toLowerCase().indexOf('version') > -1 && inBenlaiApp) {
      bridge.goback()
      return
    }
    this.$router.back()
  }
  @Emit()
  public isWeixin () {
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('micromessenger') > -1) {
      return true
    } else {
      return false
    }
  }

  @Emit()
  public async goTo (res:any, smstype:number, grantType:number) {
    console.log('>', JSON.stringify(res))
    const self = this
    /// 返回code
    ///     100：用户未登录
    ///     101：客户端未授权
    ///     102：城市更换
    ///     103：设备更换
    ///     104：绑定手机号
    ///     105：合并账号
    switch (res.code) {
      case 0:
        let url = getGoBackUrl()
        removeGoBackUrl()
        console.log('gobackurl', url)
        if (url) {
          location.replace(url)
        } else if (document.referrer.includes('/user/index') || document.referrer.includes('userHome')) {
          console.log('goback2')
          location.replace(httpHelper.mRoot)
        } else if (document.referrer.includes('passport.benlai.com/authorization')) {
          console.log('goback3')
          history.go(-2)
        } else {
          history.back()
        }
        break
      case 100:
        location.replace('/sms')
        break
      case 104:
        location.replace(`/bind?smstype=${smstype}&grantType=${grantType}`)
        break
      case 105:
        location.replace('/explain')
        break
      case 102:
        location.replace(`/abnormal?id=${encodeURIComponent(res.value.phone)}&smsType=${7}&grantType=${grantType}`)
        break
      case 103:
        location.replace(`/abnormal?id=${encodeURIComponent(res.value.phone)}&smsType=${8}&grantType=${grantType}`)
        break
    }
  }

  @Emit()
  public compareVersion (curV: string, oldV: string) {
    if (curV === oldV) return true
    if (curV === '' || oldV === '') return false
    const cur: string[] = curV.split('.')
    const old: string[] = oldV.split('.')
    let n = ''
    let o = ''
    for (let j = 0; j < cur.length; j++) {
      n = n + cur[j]
    }
    for (let k = 0; k < old.length; k++) {
      o = o + old[k]
    }
    let nV = Number(n)
    let oV = Number(o)
    return nV > oV
  }

  @Emit()
  public async codeExchangeUrl (code: string) {
    const targetCode = code || this.targetCode
    try {
      const targetRes = await httpHelper.get({
        url: 'https://unify.benlai.com/Support/Target',
        data: {
          code: targetCode
        }
      })
      const targetUrl = targetRes.value || 'https://m.benlai.com'
      location.replace(targetUrl)
    } catch (err) {
      return [err, undefined]
    }
  }
}
